import { render, staticRenderFns } from "./tags.template?vue&type=template&id=7c7b205a&scoped=true&lang=pug&"
import script from "./tags.client?vue&type=script&lang=ts&"
export * from "./tags.client?vue&type=script&lang=ts&"
import style0 from "./tags.component.vue?vue&type=style&index=0&id=7c7b205a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7b205a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/flypaper/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c7b205a')) {
      api.createRecord('7c7b205a', component.options)
    } else {
      api.reload('7c7b205a', component.options)
    }
    module.hot.accept("./tags.template?vue&type=template&id=7c7b205a&scoped=true&lang=pug&", function () {
      api.rerender('7c7b205a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/widgets/tags/tags.component.vue"
export default component.exports