import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.textOnly
    ? _c("ReadOnlyTextField", {
        attrs: {
          prependInnerIcon: _vm.prependInnerIcon,
          label: _vm.label,
          prependIcon: _vm.prependIcon,
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      })
    : _vm.textarea
    ? _c(
        VTextarea,
        _vm._g(
          _vm._b(
            {
              attrs: {
                dense:
                  _vm.$store.state.userPreferences.compactMode || _vm.dense,
                rows: _vm.rows,
                "auto-grow": _vm.autoGrow,
                filled: _vm.filledProp,
                outlined: _vm.outlined,
                solo: _vm.solo,
                required: _vm.required,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                "prepend-inner-icon": _vm.prependInnerIcon,
                "append-icon": _vm.appendInnerIcon,
                label: _vm.label,
                rules: _vm.rules,
                clearable: _vm.clearable,
              },
              model: {
                value: _vm.localValue,
                callback: function ($$v) {
                  _vm.localValue = $$v
                },
                expression: "localValue",
              },
            },
            "v-textarea",
            _vm.$props,
            false
          ),
          _vm.$listeners
        )
      )
    : _c(
        VTextField,
        _vm._g(
          _vm._b(
            {
              attrs: {
                dense:
                  _vm.$store.state.userPreferences.compactMode || _vm.dense,
                filled: _vm.filledProp,
                outlined: _vm.outlined,
                solo: _vm.solo,
                required: _vm.required,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                "prepend-inner-icon": _vm.prependInnerIcon,
                "append-icon": _vm.appendInnerIcon,
                label: _vm.label,
                rules: _vm.rules,
                clearable: _vm.clearable,
                "hide-details": "auto",
              },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return function () {
                    _vm.$emit("keydownEnter")
                  }.apply(null, arguments)
                },
              },
              model: {
                value: _vm.localValue,
                callback: function ($$v) {
                  _vm.localValue = $$v
                },
                expression: "localValue",
              },
            },
            "v-text-field",
            _vm.$props,
            false
          ),
          _vm.$listeners
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }