import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FAlert",
        { attrs: { type: "info", text: "", prominent: "", border: "bottom" } },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "grow" },
                [
                  _vm.dateRangePicker && _vm.dateRange.length === 0
                    ? [
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v("Select a date range."),
                        ]),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v("Choose a range of daily dates."),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.dateRangePicker && _vm.dateRange.length > 0
                    ? [
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v(
                            "Selected " +
                              _vm._s(
                                _vm.getDatesInBetween(
                                  _vm.dateRange[0],
                                  _vm.dateRange[_vm.dateRange.length - 1]
                                ).length
                              ) +
                              " dates"
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "subtitle font-italic font-weight-small",
                          },
                          [_vm._v(_vm._s(_vm.datesRangeToDisplay))]
                        ),
                      ]
                    : _vm._e(),
                  !_vm.dateRangePicker && _vm.dateRange.length === 0
                    ? [
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v("Select specific dates."),
                        ]),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v("Choose specific daily dates."),
                        ]),
                      ]
                    : _vm._e(),
                  !_vm.dateRangePicker && _vm.dateRange.length > 0
                    ? [
                        _c("span", { staticClass: "text-h6" }, [
                          _vm._v(
                            "Selected " +
                              _vm._s(_vm.dateRange.length) +
                              " dates"
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "subtitle font-italic font-weight-small",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("truncate")(
                                  _vm.datesToDisplay,
                                  100,
                                  "..."
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                VCol,
                { attrs: { cols: "auto" } },
                [
                  _c("FSwitch", {
                    attrs: {
                      color: "info",
                      isLight: true,
                      label: "DATE RANGE",
                      customLabelClass: "blue--text",
                    },
                    model: {
                      value: _vm.dateRangePicker,
                      callback: function ($$v) {
                        _vm.dateRangePicker = $$v
                      },
                      expression: "dateRangePicker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(VDatePicker, {
        attrs: {
          landscape: "",
          "full-width": "",
          "no-title": "",
          multiple: !_vm.dateRangePicker,
          range: _vm.dateRangePicker,
          "first-day-of-week": "1",
          "allowed-dates": function (val) {
            return _vm.getAllowedDates(val)
          },
        },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _c("FButtonToggle", {
        attrs: {
          disabledOptions: _vm.invalidRanges,
          optionList: _vm.availableRanges,
          small: "",
          activeClass: "v-btn--active green-active-btn",
        },
        on: {
          buttonClick: function (rangeType) {
            _vm.dateRange = _vm.datePresetsInLabor(rangeType)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }