import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FDropdown",
    _vm._b(
      {
        attrs: {
          "prepend-icon": _vm.iconPosition !== "Inner" ? undefined : "mdi-tag",
          "prepend-inner-icon":
            _vm.iconPosition === "Inner" ? undefined : "mdi-tag",
          allowCreateNew: "",
          textOnly: _vm.textOnly,
          readonly: _vm.readonly,
          label: _vm.label,
          filled: "",
          disabled: _vm.disabled,
          items: _vm.autoCompleteList,
          multiple: "",
          "small-chips": "",
          dense: _vm.$store.state.userPreferences.compactMode,
          clearable: false,
        },
        on: {
          input: function ($event) {
            _vm.search = $event
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.disabled
              ? {
                  key: "no-data",
                  fn: function () {
                    return [
                      _c(
                        "FAlert",
                        {
                          staticClass: "mt-4 ml-4 mr-4",
                          attrs: {
                            type: "info",
                            dense: _vm.$store.state.userPreferences.compactMode,
                            text: "",
                            prominent: "",
                            border: "left",
                          },
                        },
                        [
                          _c(
                            VRow,
                            { attrs: { align: "center", "no-gutters": "" } },
                            [
                              _c(VCol, { staticClass: "grow" }, [
                                _c("div", { staticClass: "text-h6" }, [
                                  _vm._v(
                                    "There are no such tag in this project."
                                  ),
                                ]),
                                _c("div", { staticClass: "subtitle" }, [
                                  _vm._v("Hit the "),
                                  _c("kbd", [_vm._v("enter / return")]),
                                  _vm._v(" key to create it."),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "selection",
              fn: function (data) {
                return [
                  _c(
                    VChip,
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        small: "",
                        close: !_vm.disabled,
                        color: "primary",
                        "text-color": _vm.textColorOnPrimary,
                      },
                      on: {
                        "click:close": function ($event) {
                          return _vm.remove(data.item)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { left: "", small: "" } }, [
                        _vm._v("mdi-tag"),
                      ]),
                      _vm._v(_vm._s(data.item)),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.localTagList,
          callback: function ($$v) {
            _vm.localTagList = $$v
          },
          expression: "localTagList",
        },
      },
      "FDropdown",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }