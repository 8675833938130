import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FDialog",
    {
      attrs: {
        title: "Submit your feedback to the FlyPaper team",
        doneButtonLoading: _vm.isAttachmentUploading,
      },
      on: { done: _vm.feedbackPostIssue },
      model: {
        value: _vm.localShow,
        callback: function ($$v) {
          _vm.localShow = $$v
        },
        expression: "localShow",
      },
    },
    [
      _c(
        VForm,
        {
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(VTextField, {
            attrs: {
              filled: "",
              label: "Title",
              rules: _vm.rulesTitle,
              required: "",
              dense: _vm.$store.state.userPreferences.compactMode,
            },
            model: {
              value: _vm.feedbackPostIssueData.title,
              callback: function ($$v) {
                _vm.$set(_vm.feedbackPostIssueData, "title", $$v)
              },
              expression: "feedbackPostIssueData.title",
            },
          }),
          _c(VTextarea, {
            attrs: {
              filled: "",
              label: "Description",
              rules: _vm.rulesDescription,
              rows: "1",
              "auto-grow": "",
              required: "",
            },
            model: {
              value: _vm.feedbackPostIssueData.description,
              callback: function ($$v) {
                _vm.$set(_vm.feedbackPostIssueData, "description", $$v)
              },
              expression: "feedbackPostIssueData.description",
            },
          }),
          _c("label", { staticClass: "v-label mb-1" }, [_vm._v("Attachments")]),
          _c("AttachmentsViewer", {
            attrs: {
              disableExtendedViewer: true,
              attachmentIds: _vm.attachmentIds,
            },
            on: { delete: _vm.removeAttachment },
          }),
          _c("Attachments", {
            staticClass: "mb-4",
            attrs: {
              "upload-url": "/feedback/upload/support/attachment",
              maxFileSize: _vm.maxFileSize,
              "clear-successful": "",
            },
            on: {
              uploading: _vm.isUploading,
              "updated-record": _vm.mergeAttachments,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }