import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        staticClass: "image-holder",
        attrs: { id: "viewer", src: _vm.src },
      }),
      _c(VImg, {
        key: _vm.src,
        attrs: { src: _vm.src },
        on: { click: _vm.viewFullScreen },
        scopedSlots: _vm._u([
          {
            key: "placeholder",
            fn: function () {
              return [
                _c(VProgressLinear, {
                  attrs: {
                    indeterminate: "",
                    color: "accent",
                    rounded: "",
                    height: "8",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }