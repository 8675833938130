import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FDropdown", {
    staticClass: "mt-3",
    attrs: {
      items: _vm.sortedUpdateDatesToShow,
      prependIcon: _vm.icon ? "mdi-update" : "",
      "item-text": "displayName",
      "item-value": "timestamp",
      label: _vm.labelText,
      filled: "",
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              VSheet,
              { staticStyle: { width: "400px" } },
              [
                _c(VCardTitle, { staticClass: "py-2" }, [
                  _vm._v(_vm._s(item.date) + " - " + _vm._s(item.time)),
                ]),
                _c(VCardSubtitle, { staticClass: "py-2" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.localSelectedDate,
      callback: function ($$v) {
        _vm.localSelectedDate = $$v
      },
      expression: "localSelectedDate",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }