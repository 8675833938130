import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewer" } },
    [
      _vm.attachments.length > 0
        ? _c(
            VRow,
            { staticClass: "px-2" },
            [
              _vm.enableViewer
                ? _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            VRow,
                            {
                              staticClass: "viewer-controls",
                              attrs: { align: "center", justify: "center" },
                            },
                            [
                              _vm.currentAttachment && _vm.currentAttachment.id
                                ? _c("FButton", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      fab: "",
                                      icon: "",
                                      tooltip: "Download the attachment",
                                      prependIcon: "mdi-cloud-download",
                                      disabled: _vm.isEmbedded,
                                      href: !_vm.isEmbedded
                                        ? _vm.isReadOnlySession
                                          ? "/attachments/public/download/" +
                                            _vm.currentAttachment.id +
                                            "/" +
                                            _vm.attachmentIds[0]
                                          : "/attachments/download/" +
                                            _vm.currentAttachment.id
                                        : undefined,
                                    },
                                  })
                                : _vm._e(),
                              _c("FButton", {
                                staticClass: "mx-2",
                                attrs: {
                                  fab: "",
                                  icon: "",
                                  tooltip: "Previous",
                                  prependIcon: "mdi-arrow-left-bold",
                                  disabled: _vm.attachments.length === 1,
                                },
                                on: { click: _vm.previousAttachment },
                              }),
                              _c("FButton", {
                                staticClass: "mx-2",
                                attrs: {
                                  fab: "",
                                  icon: "",
                                  tooltip: "Close",
                                  prependIcon: "mdi-close",
                                },
                                on: { click: _vm.closeViewer },
                              }),
                              _c("FButton", {
                                staticClass: "mx-2",
                                attrs: {
                                  fab: "",
                                  icon: "",
                                  tooltip: "Next",
                                  prependIcon: "mdi-arrow-right-bold",
                                  disabled: _vm.attachments.length === 1,
                                },
                                on: { click: _vm.nextAttachment },
                              }),
                              _c("FButton", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isImage,
                                    expression: "isImage",
                                  },
                                ],
                                staticClass: "mx-2",
                                attrs: {
                                  fab: "",
                                  icon: "",
                                  tooltip: "Full screen",
                                  "prepend-icon": "mdi-fullscreen",
                                },
                                on: { click: _vm.onFullScreen },
                              }),
                            ],
                            1
                          ),
                          _c(VCard, { attrs: { elevation: "10" } }, [
                            _vm.currentAttachment &&
                            _vm.currentAttachment.id &&
                            _vm.src !== ""
                              ? _c(
                                  "span",
                                  { attrs: { xs12: "" } },
                                  [
                                    _vm.type === "pdf"
                                      ? _c(
                                          "span",
                                          [
                                            _c("AttachmentsViewerDocument", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isPdf,
                                                  expression: "isPdf",
                                                },
                                              ],
                                              attrs: {
                                                src: _vm.src,
                                                isBase64: false,
                                                fullScreenMode: false,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.type === "video"
                                      ? _c(
                                          "span",
                                          [
                                            _c("AttachmentsViewerMedia", {
                                              staticClass: "video-holder",
                                              attrs: {
                                                src: _vm.src,
                                                thumbnail: _vm.thumbnail,
                                                type: _vm.mimeType,
                                                attachmentId:
                                                  _vm.currentAttachment.id,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.type === "audio"
                                      ? _c(
                                          "span",
                                          [
                                            _c("AttachmentsViewerMedia", {
                                              staticClass: "audio-holder",
                                              attrs: {
                                                isAudio: "",
                                                src: _vm.src,
                                                thumbnail: _vm.thumbnail,
                                                type: _vm.mimeType,
                                                attachmentId:
                                                  _vm.currentAttachment.id,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.type === "image"
                                      ? _c("AttachmentsViewerImage", {
                                          ref: "attachmentsViewerImage",
                                          attrs: { src: _vm.src },
                                        })
                                      : _vm._e(),
                                    _c(VRow, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "py-3",
                                          staticStyle: {
                                            "margin-left": "30px",
                                          },
                                        },
                                        _vm._l(
                                          _vm.attachmentInformation(
                                            _vm.currentAttachment
                                          ),
                                          function (info) {
                                            return _c(
                                              VListItem,
                                              {
                                                key: info,
                                                staticClass: "pl-0 mt-n4",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(info)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VScaleTransition,
                [
                  _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _vm.searchFilterShowsNoResults
                        ? _c(
                            VRow,
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                VCol,
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "FAlert",
                                    {
                                      attrs: {
                                        type: "info",
                                        text: "",
                                        prominent: "",
                                        border: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(VCol, { staticClass: "grow" }, [
                                            _vm._v(
                                              _vm._s(_vm.noResultsFoundText)
                                            ),
                                          ]),
                                          _c(
                                            VCol,
                                            { staticClass: "shrink" },
                                            [
                                              _c("FButton", {
                                                attrs: {
                                                  color: "info",
                                                  outlined: "",
                                                  prependIcon:
                                                    "mdi-window-close",
                                                  buttonText:
                                                    "Remove Search Filter",
                                                  disabled: _vm.disabled,
                                                },
                                                on: { click: _vm.clearSearch },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            VRow,
                            { attrs: { align: "center" } },
                            [
                              _c(
                                VCol,
                                [
                                  !_vm.hideExpansionPanel
                                    ? _c(
                                        VExpansionPanels,
                                        {
                                          attrs: {
                                            focusable: "",
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.panelIndexToExpand,
                                            callback: function ($$v) {
                                              _vm.panelIndexToExpand = $$v
                                            },
                                            expression: "panelIndexToExpand",
                                          },
                                        },
                                        _vm._l(
                                          _vm.attachmentsToDisplay,
                                          function (attachments, index) {
                                            return _c(
                                              VExpansionPanel,
                                              { key: index },
                                              [
                                                _c(
                                                  VExpansionPanelHeader,
                                                  {
                                                    staticClass:
                                                      "v-label text-center text-sm-left",
                                                    style:
                                                      _vm.hidePanelHeader(),
                                                  },
                                                  [
                                                    _c(
                                                      VRow,
                                                      {
                                                        attrs: {
                                                          "align-center": "",
                                                        },
                                                      },
                                                      [
                                                        _c(VCol, [
                                                          _c(
                                                            "h1",
                                                            {
                                                              staticClass:
                                                                "ml-2 text-h6 text-uppercase",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(index)
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "h2",
                                                            {
                                                              staticClass:
                                                                "ml-2 mt-2 text-caption",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  attachments.length
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    attachments.length >
                                                                      1
                                                                      ? "Attachments"
                                                                      : "Attachment"
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VExpansionPanelContent,
                                                  [
                                                    _c(VDataIterator, {
                                                      attrs: {
                                                        items: attachments,
                                                        page: _vm.page,
                                                        "hide-default-footer":
                                                          attachments.length <=
                                                          5,
                                                        "footer-props": {
                                                          "items-per-page-options":
                                                            _vm.itemsPerPageOptionsGridView,
                                                        },
                                                      },
                                                      on: {
                                                        "update:page":
                                                          function ($event) {
                                                            _vm.page = $event
                                                          },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function (
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  VRow,
                                                                  {
                                                                    attrs: {
                                                                      xs: "12",
                                                                      sm: "12",
                                                                      md: "12",
                                                                      lg: "12",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    props.items,
                                                                    function (
                                                                      attachment,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VRow,
                                                                        {
                                                                          key: attachment.id,
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "20px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              cols: "12",
                                                                              sm: "12",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VHover,
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "close-delay":
                                                                                    "300",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var hover =
                                                                                          ref.hover
                                                                                        return [
                                                                                          _c(
                                                                                            VCard,
                                                                                            _vm._g(
                                                                                              {
                                                                                                directives:
                                                                                                  [
                                                                                                    {
                                                                                                      name: "show",
                                                                                                      rawName:
                                                                                                        "v-show",
                                                                                                      value:
                                                                                                        attachment
                                                                                                          .data
                                                                                                          .is_variant ===
                                                                                                        false,
                                                                                                      expression:
                                                                                                        "attachment.data.is_variant === false",
                                                                                                    },
                                                                                                  ],
                                                                                                staticClass:
                                                                                                  "mx-auto",
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "margin-bottom":
                                                                                                      "20px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    height:
                                                                                                      _vm.thumbnailHeight,
                                                                                                    width:
                                                                                                      _vm.thumbnailWidth,
                                                                                                  },
                                                                                              },
                                                                                              !_vm.disableExtendedViewer
                                                                                                ? {
                                                                                                    click:
                                                                                                      function () {
                                                                                                        return _vm.openAttachment(
                                                                                                          attachment.id
                                                                                                        )
                                                                                                      },
                                                                                                  }
                                                                                                : {}
                                                                                            ),
                                                                                            [
                                                                                              attachment.id ===
                                                                                              _vm.documentId
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "selected",
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _c(
                                                                                                VScaleTransition,
                                                                                                [
                                                                                                  hover ||
                                                                                                  _vm.documentId ===
                                                                                                    attachment.id
                                                                                                    ? _c(
                                                                                                        VOverlay,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              absolute: true,
                                                                                                              opacity: 0.2,
                                                                                                              color:
                                                                                                                "#ffffff",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            VRow,
                                                                                                            {
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "margin-top":
                                                                                                                    "150px",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  align:
                                                                                                                    "center",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                VCol,
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      cols: "3",
                                                                                                                      sm: "3",
                                                                                                                      md:
                                                                                                                        !_vm.disableExtendedViewer &&
                                                                                                                        _vm.isReadOnlySession
                                                                                                                          ? "6"
                                                                                                                          : "3",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "FButton",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "ml-3",
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          icon: "",
                                                                                                                          color:
                                                                                                                            _vm.themedButtonColor,
                                                                                                                          href: !_vm.isEmbedded
                                                                                                                            ? _vm.isReadOnlySession
                                                                                                                              ? "/attachments/public/download/" +
                                                                                                                                attachment.id +
                                                                                                                                "/" +
                                                                                                                                _vm
                                                                                                                                  .attachmentIds[0]
                                                                                                                              : "/attachments/download/" +
                                                                                                                                attachment.id
                                                                                                                            : undefined,
                                                                                                                          tooltip:
                                                                                                                            "Download",
                                                                                                                          disabled:
                                                                                                                            _vm.isEmbedded,
                                                                                                                          prependIcon:
                                                                                                                            "mdi-cloud-download",
                                                                                                                        },
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            $event.preventDefault()
                                                                                                                            return _vm.closeViewer.apply(
                                                                                                                              null,
                                                                                                                              arguments
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    }
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                              !_vm.disableExtendedViewer
                                                                                                                ? _c(
                                                                                                                    VCol,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          cols: "3",
                                                                                                                          sm: "3",
                                                                                                                          md: "3",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "FButton",
                                                                                                                        {
                                                                                                                          staticClass:
                                                                                                                            "ml-4",
                                                                                                                          attrs:
                                                                                                                            {
                                                                                                                              color:
                                                                                                                                _vm.themedButtonColor,
                                                                                                                              icon: "",
                                                                                                                              tooltip:
                                                                                                                                "Preview",
                                                                                                                              prependIcon:
                                                                                                                                "mdi-eye",
                                                                                                                            },
                                                                                                                          on: {
                                                                                                                            click:
                                                                                                                              function (
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                $event.stopPropagation()
                                                                                                                                $event.preventDefault()
                                                                                                                                return _vm.openAttachment(
                                                                                                                                  attachment.id
                                                                                                                                )
                                                                                                                              },
                                                                                                                          },
                                                                                                                        }
                                                                                                                      ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              !_vm.isReadOnlySession
                                                                                                                ? _c(
                                                                                                                    VCol,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          cols: "3",
                                                                                                                          sm: "3",
                                                                                                                          md: !_vm.disableExtendedViewer
                                                                                                                            ? "3"
                                                                                                                            : "5",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "FButton",
                                                                                                                        {
                                                                                                                          staticClass:
                                                                                                                            "ml-4",
                                                                                                                          attrs:
                                                                                                                            {
                                                                                                                              icon: "",
                                                                                                                              disabled:
                                                                                                                                _vm.disabled,
                                                                                                                              tooltip:
                                                                                                                                "Delete",
                                                                                                                              color:
                                                                                                                                "red",
                                                                                                                              prependIcon:
                                                                                                                                "mdi-delete",
                                                                                                                            },
                                                                                                                          on: {
                                                                                                                            click:
                                                                                                                              function (
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                $event.stopPropagation()
                                                                                                                                $event.preventDefault()
                                                                                                                                return _vm.showDeleteConfirmationDialog(
                                                                                                                                  attachment.id,
                                                                                                                                  true
                                                                                                                                )
                                                                                                                              },
                                                                                                                          },
                                                                                                                        }
                                                                                                                      ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                VImg,
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      height:
                                                                                                        _vm.imageHeight,
                                                                                                      src: _vm.isReadOnlySession
                                                                                                        ? "/attachments/public/thumbnail/" +
                                                                                                          attachment.id +
                                                                                                          "/" +
                                                                                                          _vm
                                                                                                            .attachmentIds[0]
                                                                                                        : "/attachments/thumbnail/" +
                                                                                                          attachment.id,
                                                                                                      contain:
                                                                                                        _vm.thumbnailContain,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                VCardActions,
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      "close-delay":
                                                                                                        "300",
                                                                                                    },
                                                                                                  scopedSlots:
                                                                                                    _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key: "default",
                                                                                                          fn: function (
                                                                                                            ref
                                                                                                          ) {
                                                                                                            var hover =
                                                                                                              ref.hover
                                                                                                            return [
                                                                                                              _vm.determineFileClass(
                                                                                                                attachment
                                                                                                                  .data
                                                                                                                  .filename
                                                                                                              ) ===
                                                                                                              "audio"
                                                                                                                ? _c(
                                                                                                                    VIcon,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          "float-left":
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "mdi-file-music"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _vm.determineFileClass(
                                                                                                                attachment
                                                                                                                  .data
                                                                                                                  .filename
                                                                                                              ) ===
                                                                                                              "video"
                                                                                                                ? _c(
                                                                                                                    VIcon,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          "float-left":
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "mdi-file-video"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _vm.determineFileClass(
                                                                                                                attachment
                                                                                                                  .data
                                                                                                                  .filename
                                                                                                              ) ===
                                                                                                              "image"
                                                                                                                ? _c(
                                                                                                                    VIcon,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          "float-left":
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "mdi-file-image"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _vm.determineFileClass(
                                                                                                                attachment
                                                                                                                  .data
                                                                                                                  .filename
                                                                                                              ) ===
                                                                                                              "document"
                                                                                                                ? _c(
                                                                                                                    VIcon,
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          "float-left":
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "mdi-file"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              !_vm.hideFilename
                                                                                                                ? _c(
                                                                                                                    VListItemContent,
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        VListItemTitle,
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            _vm._s(
                                                                                                                              attachment
                                                                                                                                .data
                                                                                                                                .filename
                                                                                                                            )
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                            ]
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                      null,
                                                                                                      true
                                                                                                    ),
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(VDataIterator, {
                                            attrs: {
                                              items: _vm.attachments,
                                              page: _vm.page,
                                              "hide-default-footer":
                                                _vm.attachments.length <= 5,
                                              "footer-props": {
                                                "items-per-page-options":
                                                  _vm.itemsPerPageOptionsGridView,
                                              },
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                _vm.page = $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (props) {
                                                    return [
                                                      _c(
                                                        VRow,
                                                        {
                                                          attrs: {
                                                            xs: "12",
                                                            sm: "12",
                                                            md: "12",
                                                            lg: "12",
                                                          },
                                                        },
                                                        _vm._l(
                                                          props.items,
                                                          function (
                                                            attachment,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VRow,
                                                              {
                                                                key: attachment.id,
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "20px",
                                                                },
                                                                attrs: {
                                                                  cols: "12",
                                                                  sm: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(VHover, {
                                                                  attrs: {
                                                                    "close-delay":
                                                                      "300",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var hover =
                                                                              ref.hover
                                                                            return [
                                                                              _c(
                                                                                VCard,
                                                                                _vm._g(
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            attachment
                                                                                              .data
                                                                                              .is_variant ===
                                                                                            false,
                                                                                          expression:
                                                                                            "attachment.data.is_variant === false",
                                                                                        },
                                                                                      ],
                                                                                    staticClass:
                                                                                      "mx-auto",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "20px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        height:
                                                                                          _vm.thumbnailHeight,
                                                                                        width:
                                                                                          _vm.thumbnailWidth,
                                                                                      },
                                                                                  },
                                                                                  !_vm.disableExtendedViewer
                                                                                    ? {
                                                                                        click:
                                                                                          function () {
                                                                                            return _vm.openAttachment(
                                                                                              attachment.id
                                                                                            )
                                                                                          },
                                                                                      }
                                                                                    : {}
                                                                                ),
                                                                                [
                                                                                  attachment.id ===
                                                                                  _vm.documentId
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "selected",
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    VScaleTransition,
                                                                                    [
                                                                                      hover ||
                                                                                      _vm.documentId ===
                                                                                        attachment.id
                                                                                        ? _c(
                                                                                            VOverlay,
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  absolute: true,
                                                                                                  opacity: 0.2,
                                                                                                  color:
                                                                                                    "#ffffff",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                VRow,
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "margin-top":
                                                                                                        "150px",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      align:
                                                                                                        "center",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    VCol,
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          cols: "3",
                                                                                                          sm: "3",
                                                                                                          md:
                                                                                                            !_vm.disableExtendedViewer &&
                                                                                                            _vm.isReadOnlySession
                                                                                                              ? "6"
                                                                                                              : "3",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "FButton",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "ml-3",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              icon: "",
                                                                                                              color:
                                                                                                                _vm.themedButtonColor,
                                                                                                              href: !_vm.isEmbedded
                                                                                                                ? _vm.isReadOnlySession
                                                                                                                  ? "/attachments/public/download/" +
                                                                                                                    attachment.id +
                                                                                                                    "/" +
                                                                                                                    _vm
                                                                                                                      .attachmentIds[0]
                                                                                                                  : "/attachments/download/" +
                                                                                                                    attachment.id
                                                                                                                : undefined,
                                                                                                              tooltip:
                                                                                                                "Download",
                                                                                                              disabled:
                                                                                                                _vm.isEmbedded,
                                                                                                              prependIcon:
                                                                                                                "mdi-cloud-download",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                $event.preventDefault()
                                                                                                                return _vm.closeViewer.apply(
                                                                                                                  null,
                                                                                                                  arguments
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  !_vm.disableExtendedViewer
                                                                                                    ? _c(
                                                                                                        VCol,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              cols: "3",
                                                                                                              sm: "3",
                                                                                                              md: "3",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "FButton",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-4",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  color:
                                                                                                                    _vm.themedButtonColor,
                                                                                                                  icon: "",
                                                                                                                  tooltip:
                                                                                                                    "Preview",
                                                                                                                  prependIcon:
                                                                                                                    "mdi-eye",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    $event.stopPropagation()
                                                                                                                    $event.preventDefault()
                                                                                                                    return _vm.openAttachment(
                                                                                                                      attachment.id
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            }
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  !_vm.isReadOnlySession
                                                                                                    ? _c(
                                                                                                        VCol,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              cols: "3",
                                                                                                              sm: "3",
                                                                                                              md: !_vm.disableExtendedViewer
                                                                                                                ? "3"
                                                                                                                : "5",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "FButton",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-4",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  icon: "",
                                                                                                                  disabled:
                                                                                                                    _vm.disabled,
                                                                                                                  tooltip:
                                                                                                                    "Delete",
                                                                                                                  color:
                                                                                                                    "red",
                                                                                                                  prependIcon:
                                                                                                                    "mdi-delete",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    $event.stopPropagation()
                                                                                                                    $event.preventDefault()
                                                                                                                    return _vm.showDeleteConfirmationDialog(
                                                                                                                      attachment.id,
                                                                                                                      true
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            }
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    VImg,
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          height:
                                                                                            _vm.imageHeight,
                                                                                          src: _vm.isReadOnlySession
                                                                                            ? "/attachments/public/thumbnail/" +
                                                                                              attachment.id +
                                                                                              "/" +
                                                                                              _vm
                                                                                                .attachmentIds[0]
                                                                                            : "/attachments/thumbnail/" +
                                                                                              attachment.id,
                                                                                          contain:
                                                                                            _vm.thumbnailContain,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    VCardActions,
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "close-delay":
                                                                                            "300",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "default",
                                                                                              fn: function (
                                                                                                ref
                                                                                              ) {
                                                                                                var hover =
                                                                                                  ref.hover
                                                                                                return [
                                                                                                  _vm.determineFileClass(
                                                                                                    attachment
                                                                                                      .data
                                                                                                      .filename
                                                                                                  ) ===
                                                                                                  "audio"
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "float-left":
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-file-music"
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm.determineFileClass(
                                                                                                    attachment
                                                                                                      .data
                                                                                                      .filename
                                                                                                  ) ===
                                                                                                  "video"
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "float-left":
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-file-video"
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm.determineFileClass(
                                                                                                    attachment
                                                                                                      .data
                                                                                                      .filename
                                                                                                  ) ===
                                                                                                  "image"
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "float-left":
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-file-image"
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm.determineFileClass(
                                                                                                    attachment
                                                                                                      .data
                                                                                                      .filename
                                                                                                  ) ===
                                                                                                  "document"
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              "float-left":
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-file"
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  !_vm.hideFilename
                                                                                                    ? _c(
                                                                                                        VListItemContent,
                                                                                                        [
                                                                                                          _c(
                                                                                                            VListItemTitle,
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  attachment
                                                                                                                    .data
                                                                                                                    .filename
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              666986834
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "FDialog",
        {
          attrs: {
            title: _vm.attachmentsConfig.confirm_delete.title,
            doneText: _vm.attachmentsConfig.confirm_delete.button_text_confirm,
            cancelText: _vm.attachmentsConfig.confirm_delete.button_text_cancel,
          },
          on: {
            cancel: function ($event) {
              _vm.showDeleteConfirmation = false
            },
            done: function () {
              _vm.showDeleteConfirmation = false
              _vm.deleteAttachment()
            },
          },
          model: {
            value: _vm.showDeleteConfirmation,
            callback: function ($$v) {
              _vm.showDeleteConfirmation = $$v
            },
            expression: "showDeleteConfirmation",
          },
        },
        [
          _c(
            "FAlert",
            { attrs: { type: "warning", text: "", prominent: "" } },
            [_vm._v(_vm._s(_vm.attachmentsConfig.confirm_delete.subtitle))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }