import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VuetifyGoogleAutocomplete", {
    attrs: {
      id: _vm.id,
      filled: "",
      placeholder: "",
      label: "Start typing an address (or long/lat) and we'll figure it out.",
      "prepend-icon": "mdi-map-search",
      clearable: "",
      hint: _vm.what3WordsAddressMap
        ? _vm.what3WordsAddressMap
        : _vm.isBlankHint,
      "persistent-hint": _vm.what3WordsAddressMap ? true : false,
    },
    on: {
      clear: function () {
        _vm.savingStatus = "error"
      },
      placechanged: function ($event) {
        return _vm.onAddressSelected($event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "append",
          fn: function () {
            return [
              _c("SavingIndicator", {
                staticClass: "text-right",
                attrs: { status: _vm.savingStatus, xs1: "" },
              }),
            ]
          },
          proxy: true,
        },
        _vm.what3WordsAddressMap !== ""
          ? {
              key: "append-outer",
              fn: function () {
                return [
                  _c(VIcon, { on: { click: _vm.openWhat3WordsAddress } }, [
                    _vm._v("mdi-open-in-new"),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.localAddress,
      callback: function ($$v) {
        _vm.localAddress = $$v
      },
      expression: "localAddress",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }