// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-672fe6f3]:export {
  primary: #00263A;
  secondary: #000000;
  accent: #f3a712;
}`, "",{"version":3,"sources":["webpack://./assets/scss/variables.scss","webpack://./floatingActionButton.style.scss"],"names":[],"mappings":"AAkBA;EACI,gBAnBM;EAoBN,kBAlBQ;EAmBR,eApBK;ACGT","sourcesContent":["$primary: #00263A;\n$accent: #f3a712;\n$secondary: #000000;\n$body-bg: #ffffff;\n$body-color: #000000;\n$component-bg: #ffffff;\n$component-color: $primary;\n$card-cap-bg: $primary;\n$card-cap-color: #fff;\n$complementary: $primary;\n$complementary-color: #ffffff;\n$font-size: 14;\n$border-radius-root: 14px;\n$grid-gutter: 12px;\n$body-font-family: 'Roboto', sans-serif;\n$heading-font-family: 'Lato', 'Trebuchet MS', sans-serif;\n$ripple-color: #414141;\n\n:export {\n    primary: $primary;\n    secondary: $secondary;\n    accent: $accent;\n}\n",":export {\n  primary: #00263A;\n  secondary: #000000;\n  accent: #f3a712;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
