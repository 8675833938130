import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "viewer" } },
    [
      _vm.layout === "horizontal" && !_vm.smallScreen
        ? _c(
            VRow,
            {
              staticClass: "pb-2 mt-2",
              style: _vm.customStyle,
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "shrink" },
                [
                  _c(VImg, {
                    attrs: {
                      src: _vm.alertImage,
                      "max-height": "110",
                      "max-width": "110",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "shrink mt-2",
                  staticStyle: { "min-width": "210px" },
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.headerText))]),
                  _c("small", [_vm._v(_vm._s(_vm.contentText))]),
                ]
              ),
              _c(
                VCol,
                { staticClass: "shrink mt-3" },
                [
                  _c("FButton", {
                    staticClass: "mt-2",
                    attrs: {
                      outlined: "",
                      loading: _vm.loading,
                      prependIcon: _vm.buttonIcon,
                      buttonText: _vm.buttonText,
                      disabled: _vm.disabled,
                    },
                    on: {
                      click: function (x) {
                        return _vm.$emit("addOneBtn", x)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            VRow,
            {
              staticClass: "pb-2 mt-4 ml-4",
              style: _vm.customStyle,
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "shrink" },
                [
                  _c(VImg, {
                    attrs: {
                      src: _vm.alertImage,
                      "max-height": "110",
                      "max-width": "110",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "shrink" },
                [
                  _c(VRow, { attrs: { align: "center" } }, [
                    _c("h3", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm.headerText)),
                    ]),
                  ]),
                  _c(
                    VRow,
                    {
                      staticStyle: { "white-space": "pre" },
                      attrs: { align: "center" },
                    },
                    [
                      _c("small", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.contentText)),
                      ]),
                    ]
                  ),
                  _c(
                    VRow,
                    { attrs: { align: "center" } },
                    [
                      _c("FButton", {
                        staticClass: "mt-2",
                        attrs: {
                          outlined: "",
                          loading: _vm.loading,
                          prependIcon: _vm.buttonIcon,
                          buttonText: _vm.buttonText,
                          disabled: _vm.disabled,
                        },
                        on: {
                          click: function (x) {
                            return _vm.$emit("addOneBtn", x)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }