import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FDropdown", {
    staticClass: "pr-2",
    staticStyle: { width: "100px", "min-width": "80px" },
    attrs: {
      outlined: "",
      label: "Sort by",
      dense: "",
      clearable:
        _vm.sortBy === _vm.sortKeys[0].value || _vm.hideSortClerable
          ? false
          : true,
      value: _vm.sortBy,
      items: _vm.sortKeys,
      itemText: "key",
      itemValue: "value",
      "append-outer-icon": _vm.sortDesc
        ? "mdi-sort-ascending"
        : "mdi-sort-descending",
    },
    on: {
      input: function (val) {
        return _vm.updateSortBy(val)
      },
      clickAppendOuter: _vm.updateSortOrder,
    },
    scopedSlots: _vm._u(
      [
        _vm.sortKeys[0].icon
          ? {
              key: "item",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [_vm._v(_vm._s(item.key))]),
                  _vm._l(item.icon, function (icon, index) {
                    return [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v(_vm._s(_vm.getIconForRecordType(icon))),
                      ]),
                    ]
                  }),
                ]
              },
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }