import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VSnackbar,
    {
      attrs: {
        color: _vm.snackbar.current.color,
        timeout: _vm.snackbar.current.timeout,
        top: "",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function (ref) {
            var attrs = ref.attrs
            return [
              _c(
                "FButton",
                _vm._b(
                  {
                    attrs: { text: "", prependIcon: "mdi-close" },
                    on: {
                      click: function ($event) {
                        _vm.snackbar.show = false
                      },
                    },
                  },
                  "FButton",
                  attrs,
                  false
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar.show,
        callback: function ($$v) {
          _vm.$set(_vm.snackbar, "show", $$v)
        },
        expression: "snackbar.show",
      },
    },
    [_vm._v(_vm._s(_vm.snackbar.current.message))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }