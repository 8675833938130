import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAlert,
    _vm._b({}, "v-alert", _vm.$props, false),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }