import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    {
      ref: "menu",
      attrs: {
        "offset-x": _vm.offsetX,
        "offset-y": _vm.offsetY,
        "min-width": _vm.minWidth,
        "max-width": _vm.maxWidth,
        "max-height": _vm.height,
        transition: _vm.transition,
        origin: _vm.origin,
        top: false,
        "close-on-content-click": _vm.closeOnContent,
        "close-on-click": _vm.closeOnClick,
        "nudge-width": _vm.nudgeWidth,
        "nudge-bottom": _vm.nudgeBottom,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var menu = ref.on
            return [
              _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var tooltip = ref.on
                          return [
                            _vm.block
                              ? _c(
                                  "FButton",
                                  _vm._g(
                                    {
                                      attrs: {
                                        block: "",
                                        "max-width": _vm.maxWidth,
                                        tooltip: _vm.tooltipText,
                                        customStyle: _vm.buttonStyle,
                                        icon: _vm.iconButton,
                                        color: _vm.buttonColor,
                                        text: _vm.textButton,
                                        outlined: _vm.outlinedButton,
                                        disabled: _vm.disabled,
                                        buttonType: _vm.buttonType,
                                        appendIcon: _vm.appendIcon,
                                        prependIcon: _vm.appendIcon
                                          ? ""
                                          : _vm.localIcon,
                                        buttonText: _vm.buttonText,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("click")
                                        },
                                      },
                                    },
                                    Object.assign({}, menu)
                                  )
                                )
                              : _c(
                                  "FButton",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "max-width": _vm.maxWidth,
                                        outlined: _vm.outlinedButton,
                                        tooltip: _vm.tooltipText,
                                        customStyle: _vm.buttonStyle,
                                        color: _vm.buttonColor,
                                        text: _vm.textButton,
                                        icon: _vm.iconButton,
                                        disabled: _vm.disabled,
                                        buttonType: _vm.buttonType,
                                        appendIcon: _vm.appendIcon,
                                        prependIcon: _vm.appendIcon
                                          ? ""
                                          : _vm.localIcon,
                                        buttonText: _vm.buttonText,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("click")
                                        },
                                      },
                                    },
                                    Object.assign({}, menu)
                                  ),
                                  [
                                    _vm.badgeValue
                                      ? _c("FBadge", {
                                          attrs: {
                                            content: _vm.badgeValue,
                                            icon: _vm.localIcon,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.localShow,
        callback: function ($$v) {
          _vm.localShow = $$v
        },
        expression: "localShow",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }