import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VSwitch, {
    style: _vm.customStyle,
    attrs: {
      label: _vm.label,
      color: _vm.color,
      disabled: _vm.disabled,
      "prepend-icon": _vm.prependIcon,
      inset: "",
      "hide-details": _vm.hideDetails,
      light: _vm.isLight,
    },
    on: {
      change: function (x) {
        _vm.$emit("change", x)
      },
      click: function () {
        _vm.$emit("click")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [
            _c(
              "span",
              { class: _vm.customLabelClass, style: _vm.customLabelStyle },
              [_vm._v(_vm._s(_vm.label))]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }