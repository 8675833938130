var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FDropdown", {
    staticClass: "pr-2",
    staticStyle: { width: "100px" },
    attrs: {
      label: "Group by",
      dense: "",
      outlined: "",
      value: _vm.groupBy,
      items: _vm.groupKeys,
      itemText: "key",
      itemValue: "value",
    },
    on: {
      input: function (val) {
        return _vm.updateGroupBy(val)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }