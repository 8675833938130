import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    [
      _c(
        VCol,
        { attrs: { cols: "12", md: 12 } },
        [
          _c(
            VRow,
            [
              !_vm.hideSearch
                ? _c(
                    VCol,
                    { staticClass: "ui icon input" },
                    [
                      _c(VTextField, {
                        attrs: {
                          clearable: "",
                          "hide-details": "",
                          outlined: "",
                          dense:
                            _vm.$store.state.userPreferences.compactMode ||
                            _vm.dense,
                          "prepend-inner-icon": "mdi-filter-variant",
                          label: _vm.label,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c(
                                    VMenu,
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "offset-x": "",
                                        transition: "scale-transition",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "FButton",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-n4",
                                                      attrs: {
                                                        tooltip:
                                                          "Advanced filtering.",
                                                        icon: "",
                                                        small:
                                                          _vm.$store.state
                                                            .userPreferences
                                                            .compactMode,
                                                        prependIcon:
                                                          "mdi-lightbulb-on",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showTips = true
                                                        },
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        874350331
                                      ),
                                    },
                                    [
                                      _vm.showTips
                                        ? _c(
                                            VSheet,
                                            [
                                              _c(VCardTitle, [
                                                _vm._v("Advanced filtering"),
                                              ]),
                                              _c(VCardSubtitle, [
                                                _vm._v(
                                                  "Using the filtering syntax below you will be able to fine-tune your results."
                                                ),
                                              ]),
                                              _c(
                                                VCardText,
                                                [
                                                  _c(VSimpleTable, {
                                                    staticClass: "pl-6",
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function () {
                                                            return [
                                                              _c("thead"),
                                                              _c("tr", [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-left",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Keyword"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-left",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Type"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-left",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Description"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _c("tbody"),
                                                              _vm._l(
                                                                _vm.searchTipsContent,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.search
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.matchType
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.description
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1496191656
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "FAlert",
                                                {
                                                  staticClass: "ml-2 mr-2 mt-2",
                                                  attrs: {
                                                    type: "info",
                                                    text: "",
                                                    prominent: "",
                                                    border: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "White space acts as an AND operator, while a single pipe (|) character acts as an OR operator. "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    '   To escape white space, use double quote ex. ="scheme language" for exact match.'
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          770415214
                        ),
                        model: {
                          value: _vm.localSearch,
                          callback: function ($$v) {
                            _vm.localSearch = $$v
                          },
                          expression: "localSearch",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.groupKeys && _vm.groupKeys.length > 0
                ? _c(
                    VCol,
                    [
                      _c("FDropdown", {
                        attrs: {
                          outlined: "",
                          clearable:
                            _vm.localGroupBy === _vm.groupKeys[0].value
                              ? false
                              : true,
                          "hide-details": "",
                          dense:
                            _vm.$store.state.userPreferences.compactMode ||
                            _vm.dense,
                          items: _vm.groupKeys,
                          itemText: "key",
                          itemValue: "value",
                          label: "Group by",
                        },
                        on: {
                          clickClear: function ($event) {
                            _vm.localGroupBy = _vm.groupKeys[0].value
                          },
                        },
                        model: {
                          value: _vm.localGroupBy,
                          callback: function ($$v) {
                            _vm.localGroupBy = $$v
                          },
                          expression: "localGroupBy",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sortKeys && _vm.sortKeys.length > 0
                ? _c(
                    VCol,
                    [
                      _c("FDropdown", {
                        attrs: {
                          outlined: "",
                          clearable:
                            _vm.localSortBy === _vm.sortKeys[0].value ||
                            _vm.hideSortClerable
                              ? false
                              : true,
                          "hide-details": "",
                          dense:
                            _vm.$store.state.userPreferences.compactMode ||
                            _vm.dense,
                          items: _vm.sortKeys,
                          itemText: "key",
                          itemValue: "value",
                          label: "Sort by",
                        },
                        on: {
                          clickClear: function ($event) {
                            _vm.localSortBy = _vm.sortKeys[0].value
                          },
                        },
                        model: {
                          value: _vm.localSortBy,
                          callback: function ($$v) {
                            _vm.localSortBy = $$v
                          },
                          expression: "localSortBy",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sortKeys && _vm.sortKeys.length > 0
                ? _c(
                    VCol,
                    { staticClass: "shrink" },
                    [
                      _c("FButton", {
                        attrs: {
                          tooltip: "Sort",
                          icon: "",
                          prependIcon: _vm.localSortDesc
                            ? "mdi-sort-ascending"
                            : "mdi-sort-descending",
                        },
                        on: { click: _vm.toggleSortDesc },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }