import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VAppBar,
    _vm._b(
      { attrs: { fixed: false, app: "" } },
      "v-app-bar",
      _vm.$props,
      false
    ),
    [
      _vm.user
        ? _c(
            VAppBarNavIcon,
            {
              attrs: { "aria-label": "Open menu" },
              on: {
                click: function ($event) {
                  return _vm.$root.$emit("openDrawer")
                },
              },
            },
            [_c(VIcon, [_vm._v("mdi-menu")])],
            1
          )
        : _vm._e(),
      _c("FToolbar", { attrs: { title: "title", flat: "" } }),
      _c(VSpacer),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }