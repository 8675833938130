import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdfViewer-parent", attrs: { id: "PDFjs" } },
    [
      !_vm.blankStickyMode
        ? _c(
            VAppBar,
            [
              _c(
                VRow,
                { staticClass: "align-center" },
                [
                  _c("FButton", {
                    attrs: {
                      icon: "",
                      disabled: _vm.currentPage === 1 || _vm.pageRendering,
                      tooltip: "Previous Page.",
                      prependIcon: "mdi-arrow-up-bold",
                    },
                    on: {
                      click: function ($event) {
                        _vm.currentPage--
                      },
                    },
                  }),
                  _c("FButton", {
                    attrs: {
                      icon: "",
                      disabled:
                        _vm.pageCount < 2 ||
                        _vm.pageRendering ||
                        _vm.pageCount === _vm.currentPage,
                      tooltip: "Next Page.",
                      prependIcon: "mdi-arrow-down-bold",
                    },
                    on: {
                      click: function ($event) {
                        _vm.currentPage++
                      },
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "ma-1 pageNumber",
                    attrs: {
                      label: "Solo",
                      solo: "",
                      readonly: "",
                      dense: _vm.$store.state.userPreferences.compactMode,
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v("of " + _vm._s(_vm.pageCount)),
                  ]),
                  _c(VSpacer),
                  _vm.fullScreenMode
                    ? _c("FButton", {
                        attrs: {
                          icon: "",
                          disabled: _vm.scale === 40 || _vm.pageRendering,
                          tooltip: "Zoom Out.",
                          prependIcon: "mdi-magnify-minus",
                        },
                        on: {
                          click: function ($event) {
                            _vm.scale = _vm.scale - 10
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.fullScreenMode
                    ? _c("FButton", {
                        attrs: {
                          icon: "",
                          disabled: _vm.scale === 160 || _vm.pageRendering,
                          tooltip: "Zoom In.",
                          prependIcon: "mdi-magnify-plus",
                        },
                        on: {
                          click: function ($event) {
                            _vm.scale = _vm.scale + 10
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.fullScreenMode
                    ? _c("FDropdown", {
                        staticClass: "ma-3 zoomLevel",
                        attrs: {
                          solo: "",
                          items: _vm.scaleLevel,
                          dense: _vm.$store.state.userPreferences.compactMode,
                        },
                        model: {
                          value: _vm.scale,
                          callback: function ($$v) {
                            _vm.scale = $$v
                          },
                          expression: "scale",
                        },
                      })
                    : _vm._e(),
                  _vm.fullScreenMode
                    ? _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v("%"),
                      ])
                    : _vm._e(),
                  _c(VSpacer),
                  _c("FButton", {
                    attrs: {
                      icon: "",
                      disabled: _vm.pageRendering || _vm.pageCount === 0,
                      tooltip: "Presentation mode.",
                      prependIcon: "mdi-presentation",
                    },
                    on: { click: _vm.goFullScreen },
                  }),
                  _c(
                    VMenu,
                    {
                      attrs: { top: "", "close-on-content-click": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "FButton",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.pageRendering ||
                                          _vm.pageCount === 0,
                                        tooltip: "View print options.",
                                        prependIcon: "mdi-printer",
                                      },
                                      on: { click: _vm.initPrintDocument },
                                    },
                                    _vm.viewPrintMenuMode ? on : undefined
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        690309778
                      ),
                    },
                    [
                      _c(
                        VList,
                        _vm._l(_vm.buttonTexts, function (item, index) {
                          return _c(
                            VListItem,
                            { key: index },
                            [
                              _c("FButton", {
                                attrs: {
                                  buttonText: item,
                                  outlined: "",
                                  tooltip: "item",
                                  width: "160px",
                                  disabled: _vm.pageRendering,
                                  prependIcon: "mdi-printer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.printPdf(index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.fullScreenMode && _vm.downloadSrcs.length === 0
                    ? _c("FButton", {
                        attrs: {
                          icon: "",
                          disabled: _vm.pageRendering,
                          tooltip: "Download",
                          prependIcon: "mdi-download",
                        },
                        on: { click: _vm.downloadPdf },
                      })
                    : _vm._e(),
                  _vm.fullScreenMode && _vm.downloadSrcs.length > 0
                    ? _c("FButton", {
                        attrs: {
                          icon: "",
                          disabled: _vm.pageRendering,
                          tooltip: "Download multiple files",
                          prependIcon: "mdi-download",
                        },
                        on: { click: _vm.downloadMultiPdf },
                      })
                    : _vm._e(),
                  _vm.fullScreenMode
                    ? _c("FButton", {
                        attrs: {
                          icon: "",
                          disabled: _vm.pageRendering,
                          tooltip: "Close",
                          prependIcon: "mdi-close",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VContainer,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pageRendering || _vm.pageCount === 0,
              expression: "pageRendering || pageCount === 0",
            },
          ],
          staticClass: "loading",
          attrs: { md12: "", lg12: "" },
        },
        [
          _c(
            VRow,
            {
              staticStyle: { "margin-top": "25px" },
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                {
                  staticClass:
                    "text-h6 justify-center font-weight-bold text-uppercase",
                  attrs: { cols: "auto" },
                },
                [_vm._v("Loading PDF Document")]
              ),
            ],
            1
          ),
          _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _c(
                VRow,
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    VCol,
                    { attrs: { cols: "auto" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          width: 3,
                          size: 50,
                          color: "#77625e",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VContainer,
        {
          staticClass: "viewer-parent",
          attrs: { id: "pdfViewer", md12: "", lg12: "" },
        },
        [
          _vm.blankStickyMode
            ? _c("canvas", {
                staticClass: "blankStickypdfViewer",
                attrs: { id: "blankStickyViewer" },
              })
            : _c("canvas", {
                staticClass: "pdfViewer",
                attrs: { id: "viewer" },
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }