import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VTextField, {
    attrs: {
      clearable: "",
      "hide-details": "",
      outlined: "",
      dense: "",
      "prepend-inner-icon": "mdi-filter-variant",
      label: _vm.label,
    },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _c(
              VMenu,
              {
                attrs: {
                  "close-on-content-click": false,
                  "offset-x": "",
                  transition: "scale-transition",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "FIcon",
                          _vm._g(
                            {
                              attrs: {
                                value: "mdi-lightbulb-on",
                                tooltip: "Advanced filtering.",
                                small:
                                  _vm.$store.state.userPreferences.compactMode,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showTips = true
                                },
                              },
                            },
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm.showTips
                  ? _c(
                      VSheet,
                      [
                        _c(VCardTitle, [_vm._v("Advanced filtering")]),
                        _c(VCardSubtitle, [
                          _vm._v(
                            "Using the filtering syntax below you will be able to fine-tune your results."
                          ),
                        ]),
                        _c(
                          VCardText,
                          [
                            _c(VSimpleTable, {
                              staticClass: "pl-6",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead"),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Keyword")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Type")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Description")]
                                          ),
                                        ]),
                                        _c("tbody"),
                                        _vm._l(
                                          _vm.searchTipsContent,
                                          function (item) {
                                            return _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [_vm._v(_vm._s(item.search))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [_vm._v(_vm._s(item.matchType))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1496191656
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "FAlert",
                          {
                            staticClass: "ml-2 mr-2 mt-2",
                            attrs: {
                              type: "info",
                              text: "",
                              prominent: "",
                              border: "left",
                            },
                          },
                          [
                            _vm._v(
                              "White space acts as an AND operator, while a single pipe (|) character acts as an OR operator. "
                            ),
                            _c("br"),
                            _vm._v(
                              '   To escape white space, use double quote ex. ="scheme language" for exact match.'
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.localSearch,
      callback: function ($$v) {
        _vm.localSearch = $$v
      },
      expression: "localSearch",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }