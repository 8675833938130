var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attachmentsUploader" },
    [
      !_vm.disabled
        ? _c("vue-dropzone", {
            key: _vm.uploaderOptions.url,
            ref: _vm.refGuid,
            attrs: { id: "dropzone", options: _vm.uploaderOptions },
            on: {
              "vdropzone-file-added": _vm.addedFileToUpload,
              "vdropzone-queue-complete": _vm.onQueueComplete,
              "vdropzone-success": _vm.success,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }