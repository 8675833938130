import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VIcon, _vm._g({}, _vm.$listeners), [_vm._v(_vm._s(_vm.value))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }