import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c(VDataTable, {
        ref: "dataTable",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          "disable-pagination": _vm.disablePagination,
          "hide-default-footer": _vm.hideDefaultFooter,
        },
        on: {
          "click:row": function (x, y) {
            return _vm.$emit("row-click", x, y)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }