import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.textOnly
    ? _c("ReadOnlyTextField", {
        attrs: {
          appendIcon: _vm.appendIcon,
          prependIcon: _vm.prependIcon,
          prependInnerIcon: _vm.prependInnerIcon,
          label: _vm.label,
        },
        model: {
          value: _vm.selectedText,
          callback: function ($$v) {
            _vm.selectedText = $$v
          },
          expression: "selectedText",
        },
      })
    : _vm.allowCreateNew
    ? _c(
        VCombobox,
        _vm._b(
          {
            ref: "combobox",
            attrs: {
              items: _vm.items,
              "item-color": _vm.textColorOnListItems,
              "search-input": _vm.localSearch,
              "item-disabled": _vm.itemNeedsToDisable,
              "hide-details": "auto",
              "append-icon": _vm.appendIcon,
              "append-outer-icon": _vm.appendOuterIcon,
              "item-text": _vm.itemText,
              "item-value": _vm.itemValue,
              "return-object": _vm.itemValue ? true : _vm.returnObject,
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.localSearch = $event
              },
              "update:search-input": function ($event) {
                _vm.localSearch = $event
              },
              focus: _vm.focus,
              click: _vm.click,
              reset: _vm.reset,
              mousedown: _vm.mousedown,
              mouseup: _vm.mouseup,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return function () {
                  _vm.$emit("keydownEnter")
                }.apply(null, arguments)
              },
              blur: _vm.blur,
              "click:append": function () {
                _vm.$emit("clickAppend")
              },
              "click:append-outer": function () {
                _vm.$emit("clickAppendOuter")
              },
              "click:clear": function () {
                _vm.$emit("clickClear")
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, name) {
                  return {
                    key: name,
                    fn: function (slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItemValue,
              callback: function ($$v) {
                _vm.selectedItemValue = $$v
              },
              expression: "selectedItemValue",
            },
          },
          "v-combobox",
          _vm.$props,
          false
        ),
        [
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      )
    : _vm.allowAutocomplete
    ? _c(
        VAutocomplete,
        _vm._b(
          {
            ref: "autocomplete",
            attrs: {
              "search-input": _vm.localSearch,
              items: _vm.items,
              "item-color": _vm.textColorOnListItems,
              filter: _vm.filter,
              "item-value": _vm.itemValue,
              "item-text": _vm.itemText,
              "return-object": _vm.returnObject,
              "hide-details": "auto",
              "item-disabled": _vm.itemNeedsToDisable,
              "append-icon": _vm.appendIcon,
              "append-outer-icon": _vm.appendOuterIcon,
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.localSearch = $event
              },
              "update:search-input": function ($event) {
                _vm.localSearch = $event
              },
              "click:clear": function () {
                _vm.$emit("clickClear")
              },
              "click:append": function () {
                _vm.$emit("clickAppend")
              },
              "click:append-outer": function () {
                _vm.$emit("clickAppendOuter")
              },
              focus: _vm.focus,
              click: _vm.click,
              reset: _vm.reset,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return function () {
                    _vm.$emit("keydownEnter")
                  }.apply(null, arguments)
                },
                _vm.keydown,
              ],
              mousedown: _vm.mousedown,
              mouseup: _vm.mouseup,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, name) {
                  return {
                    key: name,
                    fn: function (slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItemValue,
              callback: function ($$v) {
                _vm.selectedItemValue = $$v
              },
              expression: "selectedItemValue",
            },
          },
          "v-autocomplete",
          _vm.$props,
          false
        ),
        [
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      )
    : _c(
        VSelect,
        _vm._b(
          {
            ref: "select",
            attrs: {
              items: _vm.items,
              "item-color": _vm.textColorOnListItems,
              "hide-details": "auto",
              "item-text": _vm.itemText,
              "item-value": _vm.itemValue,
              returnObject: _vm.returnObject,
              "item-disabled": _vm.itemNeedsToDisable,
              "append-icon": _vm.appendIcon,
              "append-outer-icon": _vm.appendOuterIcon,
              "menu-props": _vm.menuProps,
              id: _vm.elementID,
            },
            on: {
              "click:clear": function () {
                _vm.$emit("clickClear")
              },
              "click:append": function () {
                _vm.$emit("clickAppend")
              },
              "click:append-outer": function () {
                _vm.$emit("clickAppendOuter")
              },
              focus: _vm.focus,
              click: _vm.click,
              reset: _vm.reset,
              blur: _vm.blur,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return function () {
                    _vm.$emit("keydownEnter")
                  }.apply(null, arguments)
                },
                _vm.keydown,
              ],
              mousedown: _vm.mousedown,
              mouseup: _vm.mouseup,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, name) {
                  return {
                    key: name,
                    fn: function (slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItemValue,
              callback: function ($$v) {
                _vm.selectedItemValue = $$v
              },
              expression: "selectedItemValue",
            },
          },
          "v-select",
          _vm.$props,
          false
        ),
        [
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }