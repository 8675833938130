var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "attachments" } },
    [
      _vm.dialogButtonAttachmentWithViewer
        ? [
            _c("FButton", {
              attrs: {
                outlined: "",
                prependIcon: "mdi-paperclip",
                badgeCount: _vm.badge,
                tooltip:
                  _vm.attachmentsConfig.dialogButtonAttachmentWithViewer
                    .tooltip,
              },
              on: {
                click: function ($event) {
                  _vm.showDialog = !_vm.showDialog
                },
              },
            }),
            _c(
              "FDialog",
              {
                attrs: {
                  title:
                    _vm.attachmentsConfig.dialogButtonAttachmentWithViewer
                      .title,
                  width: "900px",
                  "max-width": "100%",
                  "hide-cancel-and-done": "",
                },
                model: {
                  value: _vm.showDialog,
                  callback: function ($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog",
                },
              },
              [
                _c("AttachmentsViewer", {
                  attrs: {
                    attachmentIds: _vm.dialogButtonAttachmentWithViewer,
                    disabled: _vm.disabled,
                  },
                  on: {
                    delete: function (data) {
                      return _vm.$emit("delete", data)
                    },
                    openAttachment: function (data) {
                      return _vm.$emit("openAttachment", data)
                    },
                  },
                }),
                _c("AttachmentsUploader", {
                  attrs: {
                    uploadUrl: _vm.uploadUrl,
                    disabled: _vm.disabled,
                    uploadNow: _vm.uploadNow,
                    uploadOnSave: _vm.uploadOnSave,
                    clearSuccessful: _vm.clearSuccessful,
                    allowSingleUpload: _vm.allowSingleUpload,
                    acceptedFiles: _vm.acceptedFiles,
                  },
                  on: {
                    "updated-record": function (data) {
                      return _vm.$emit("updated-record", data)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.dialogButtonAttachment
        ? [
            _c("FButton", {
              attrs: {
                outlined: "",
                badgeCount: _vm.badge,
                prependIcon: "mdi-paperclip",
                disabled: _vm.disabled,
                tooltip: _vm.attachmentsConfig.dialogButtonAttachment.tooltip,
              },
              on: {
                click: function ($event) {
                  _vm.showDialog = !_vm.showDialog
                },
              },
            }),
            _c(
              "FDialog",
              {
                attrs: {
                  title: _vm.attachmentsConfig.dialogButtonAttachment.title,
                  "hide-cancel-and-done": "",
                },
                model: {
                  value: _vm.showDialog,
                  callback: function ($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog",
                },
              },
              [
                _c("AttachmentsUploader", {
                  attrs: {
                    uploadUrl: _vm.uploadUrl,
                    disabled: _vm.disabled,
                    uploadNow: _vm.uploadNow,
                    uploadOnSave: _vm.uploadOnSave,
                    clearSuccessful: _vm.clearSuccessful,
                    allowSingleUpload: _vm.allowSingleUpload,
                    acceptedFiles: _vm.acceptedFiles,
                  },
                  on: {
                    "updated-record": function (data) {
                      return _vm.$emit("updated-record", data)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.dialogButtonBlockAdd
        ? [
            _c("FButton", {
              attrs: {
                outlined: "",
                block: "",
                prependIcon: "mdi-plus-circle",
                buttonText: "add",
                disabled: _vm.disabled,
                tooltip: _vm.attachmentsConfig.dialogButtonBlockAdd.tooltip,
              },
              on: {
                click: function ($event) {
                  _vm.showDialog = !_vm.showDialog
                },
              },
            }),
            _c(
              "FDialog",
              {
                attrs: {
                  title: _vm.attachmentsConfig.dialogButtonBlockAdd.title,
                  "hide-cancel-and-done": "",
                },
                model: {
                  value: _vm.showDialog,
                  callback: function ($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog",
                },
              },
              [
                _c("AttachmentsUploader", {
                  attrs: {
                    uploadUrl: _vm.uploadUrl,
                    disabled: _vm.disabled,
                    uploadNow: _vm.uploadNow,
                    uploadOnSave: _vm.uploadOnSave,
                    clearSuccessful: _vm.clearSuccessful,
                    allowSingleUpload: _vm.allowSingleUpload,
                    acceptedFiles: _vm.acceptedFiles,
                  },
                  on: {
                    "updated-record": function (data) {
                      return _vm.$emit("updated-record", data)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.dialogButtonAttachmentWithViewer &&
      !_vm.dialogButtonAttachment &&
      !_vm.dialogButtonBlockAdd
        ? [
            _c("AttachmentsUploader", {
              attrs: {
                uploadUrl: _vm.uploadUrl,
                disabled: _vm.disabled,
                uploadNow: _vm.uploadNow,
                uploadOnSave: _vm.uploadOnSave,
                clearSuccessful: _vm.clearSuccessful,
                allowSingleUpload: _vm.allowSingleUpload,
                acceptedFiles: _vm.acceptedFiles,
                maxFileSize: _vm.maxFileSize,
              },
              on: {
                "updated-record": function (data) {
                  return _vm.$emit("updated-record", data)
                },
                uploading: _vm.uploading,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }