// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*[data-v-d9c3f59a] .v-alert--prominent .v-alert__icon::after {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/fAlert/fAlert.style.web.less","webpack://./fAlert.style.web.less"],"names":[],"mappings":"AAAA;EACI,wBAAA;ACCJ","sourcesContent":["* /deep/ .v-alert--prominent .v-alert__icon::after {\n    display: none !important;\n}\n","* /deep/ .v-alert--prominent .v-alert__icon::after {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
