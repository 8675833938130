// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
ActionBar[data-v-190a8fa5] {
    flat: true;
    background-color: #00263a;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/fAppBar/fAppBar.component.vue"],"names":[],"mappings":";AAGA;IACA,UAAA;IACA,yBAAA;AACA","sourcesContent":["<script lang=\"ts\" src=\"./fAppBar.client\"></script>\n<template lang=\"pug\" src=\"./fAppBar.template\"></template>\n<style scoped>\nActionBar {\n    flat: true;\n    background-color: #00263a;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
