import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VTooltip,
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "span",
                _vm._g({}, on),
                [
                  !_vm.inProgress
                    ? _c(VIcon, { attrs: { color: _vm.iconColor } }, [
                        _vm._v(_vm._s(_vm.iconToShow)),
                      ])
                    : _c(VProgressCircular, {
                        attrs: { indeterminate: "", size: 24, color: "accent" },
                      }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.message))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }