import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "readOnlyTextField" } },
    [
      _vm.prependInnerIcon
        ? _c(
            VCard,
            { staticClass: "mx-auto", attrs: { outlined: "", flat: "" } },
            [
              _c(
                VListItem,
                { staticClass: "ml-n2" },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v(_vm._s(_vm.icon))])],
                    1
                  ),
                  _c(
                    VListItemContent,
                    {
                      staticClass: "ml-n6",
                      staticStyle: { "min-height": "59px" },
                    },
                    [
                      _c(
                        VListItemSubtitle,
                        {
                          staticClass: "subtitle",
                          staticStyle: { "font-size": "12px" },
                        },
                        [_vm._v(_vm._s(_vm.label))]
                      ),
                      _c(
                        VListItemTitle,
                        {
                          staticClass: "text-h8",
                          staticStyle: {
                            overflow: "visible",
                            "white-space": "normal",
                          },
                        },
                        [_vm._v(_vm._s(_vm.localValue))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            VListItem,
            { staticClass: "mx-auto mt-n4" },
            [
              _vm.icon
                ? _c(
                    VListItemIcon,
                    { staticClass: "mt-9" },
                    [_c(VIcon, [_vm._v(_vm._s(_vm.icon))])],
                    1
                  )
                : _vm._e(),
              _c(
                VListItemContent,
                { staticClass: "mt-1 mx-n4" },
                [
                  _c(
                    VCard,
                    { staticClass: "pl-4", attrs: { outlined: "", flat: "" } },
                    [
                      _c(
                        VListItemContent,
                        {
                          staticClass: "ml-n3",
                          staticStyle: { "min-height": "59px" },
                        },
                        [
                          _c(
                            VListItemSubtitle,
                            {
                              staticClass: "subtitle",
                              staticStyle: { "font-size": "12px" },
                            },
                            [_vm._v(_vm._s(_vm.label))]
                          ),
                          _c(
                            VListItemTitle,
                            {
                              staticClass: "text-h8",
                              staticStyle: {
                                overflow: "visible",
                                "white-space": "normal",
                              },
                            },
                            [_vm._v(_vm._s(_vm.localValue))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }