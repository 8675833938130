import { render, staticRenderFns } from "./groupBySortBy.template?vue&type=template&id=53cf1fea&lang=pug&"
import script from "./groupBySortBy.client?vue&type=script&lang=ts&"
export * from "./groupBySortBy.client?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/flypaper/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53cf1fea')) {
      api.createRecord('53cf1fea', component.options)
    } else {
      api.reload('53cf1fea', component.options)
    }
    module.hot.accept("./groupBySortBy.template?vue&type=template&id=53cf1fea&lang=pug&", function () {
      api.rerender('53cf1fea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/widgets/groupBySortBy/groupBySortBy.component.vue"
export default component.exports