import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "tutorials" } },
    [
      _c(
        VSheet,
        { staticClass: "pa-2" },
        [
          !_vm.compactView
            ? _c(
                VContainer,
                [
                  _c(VDataIterator, {
                    attrs: {
                      items: _vm.tutorialsToDisplay,
                      search: _vm.search,
                      "sort-by": _vm.sortBy ? _vm.sortBy.toLowerCase() : "",
                      "sort-desc": _vm.sortDesc,
                      "hide-default-footer":
                        !_vm.showMoreTutorials ||
                        _vm.tutorialsToDisplay.length <=
                          _vm.minimumFilterableTutorials,
                      "items-per-page": _vm.showMoreTutorials
                        ? _vm.itemsPerPage
                        : _vm.defaultNumberOfTutorials,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions,
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                VCard,
                                { staticClass: "pb-4", attrs: { flat: "" } },
                                [
                                  _c(
                                    VRow,
                                    {
                                      attrs: {
                                        dense:
                                          _vm.$store.state.userPreferences
                                            .compactMode,
                                      },
                                    },
                                    _vm._l(
                                      props.items,
                                      function (tutorial, index) {
                                        return _c(
                                          VCol,
                                          { key: index },
                                          [
                                            _c(
                                              VCard,
                                              {
                                                attrs: {
                                                  width: "320",
                                                  flat: _vm.$store.state
                                                    .userPreferences
                                                    .compactMode,
                                                  hover: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "video",
                                                  {
                                                    attrs: {
                                                      width: "320",
                                                      height: "200",
                                                      autoplay: "",
                                                      loop: "",
                                                    },
                                                  },
                                                  [
                                                    _c("source", {
                                                      attrs: {
                                                        src: _vm.getSrcPath(
                                                          tutorial.assets.web
                                                            .screencasts[0].path
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  VCardTitle,
                                                  {
                                                    staticClass:
                                                      "text-h6 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(tutorial.title)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  VCardSubtitle,
                                                  {
                                                    staticClass:
                                                      "text-subtitle-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        tutorial.description
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-4" },
                                                  _vm._l(
                                                    tutorial.tags,
                                                    function (tag, index) {
                                                      return _c(
                                                        VChip,
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            label: "",
                                                            outlined: "",
                                                          },
                                                        },
                                                        [
                                                          _c(VIcon, [
                                                            _vm._v("mdi-label"),
                                                          ]),
                                                          _vm._v(_vm._s(tag)),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(VCardText, [
                                                  _vm._v(_vm._s(tutorial.body)),
                                                ]),
                                                _c(
                                                  VCardActions,
                                                  [
                                                    _c("FButton", {
                                                      attrs: {
                                                        buttonText: "open",
                                                        prependIcon:
                                                          "mdi-book-open",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openTutorial(
                                                            tutorial
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showMoreTutorials
                                ? [
                                    _c(
                                      "FAlert",
                                      {
                                        attrs: {
                                          type: "info",
                                          prominent: "",
                                          text: "",
                                          border: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          {
                                            attrs: {
                                              align: "center",
                                              "no-gutters": "",
                                              dense:
                                                _vm.$store.state.userPreferences
                                                  .compactMode,
                                            },
                                          },
                                          [
                                            _c(
                                              VCol,
                                              { staticClass: "grow" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "text-h6" },
                                                  [
                                                    _vm._v(
                                                      "Have an idea for a tutorial?"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              VCol,
                                              { staticClass: "shrink" },
                                              [
                                                _c("FButton", {
                                                  attrs: {
                                                    color: "info",
                                                    outlined: "",
                                                    prependIcon:
                                                      "mdi-comment-quote",
                                                    buttonText: "Tell us",
                                                    tooltip:
                                                      "Send some feedback to FlyPaper or ask a question via email.",
                                                  },
                                                  on: {
                                                    click: _vm.suggestATutorial,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                "FAlert",
                                {
                                  attrs: {
                                    type: "info",
                                    prominent: "",
                                    text: "",
                                    border: "left",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                        dense:
                                          _vm.$store.state.userPreferences
                                            .compactMode,
                                      },
                                    },
                                    [
                                      _c(VCol, { staticClass: "grow" }, [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v(
                                            "Have an idea for a tutorial?"
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        VCol,
                                        { staticClass: "shrink" },
                                        [
                                          _c("FButton", {
                                            attrs: {
                                              color: "info",
                                              outlined: "",
                                              prependIcon: "mdi-comment-quote",
                                              buttonText: "Tell us",
                                              tooltip:
                                                "Send some feedback to FlyPaper or ask a question via email.",
                                            },
                                            on: { click: _vm.suggestATutorial },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                "FAlert",
                                {
                                  attrs: {
                                    type: "info",
                                    prominent: "",
                                    text: "",
                                    border: "left",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                        dense:
                                          _vm.$store.state.userPreferences
                                            .compactMode,
                                      },
                                    },
                                    [
                                      _c(VCol, { staticClass: "grow" }, [
                                        _c("div", { staticClass: "text-h6" }, [
                                          _vm._v(
                                            "Have an idea for a tutorial?"
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        VCol,
                                        { staticClass: "shrink" },
                                        [
                                          _c("FButton", {
                                            attrs: {
                                              color: "info",
                                              outlined: "",
                                              prependIcon: "mdi-comment-quote",
                                              buttonText: "Tell us",
                                              tooltip:
                                                "Send some feedback to FlyPaper or ask a question via email.",
                                            },
                                            on: { click: _vm.suggestATutorial },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2427088599
                    ),
                  }),
                  _vm.limitResults &&
                  _vm.allTutorials.length > _vm.defaultNumberOfTutorials
                    ? _c(
                        VRow,
                        { staticClass: "justify-center mt-4" },
                        [
                          _c("FButton", {
                            attrs: {
                              text: "",
                              tooltip: _vm.showMoreTutorials
                                ? "Show less."
                                : "Show more.",
                              "prepend-icon": _vm.showMoreTutorials
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showMoreTutorials = !_vm.showMoreTutorials
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._l(_vm.tutorialsToDisplay, function (tutorial) {
                return _c(
                  VListItem,
                  { key: tutorial.title },
                  [
                    _c(
                      VListItemContent,
                      [
                        _c(
                          VCard,
                          {
                            attrs: { flat: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openTutorial(tutorial)
                              },
                            },
                          },
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass: "my-auto",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c(VImg, {
                                      attrs: {
                                        width: "180",
                                        src: _vm.getSrcPath(
                                          tutorial.assets.web.snapshots[0].path
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  {
                                    staticClass: "ml-2 my-auto",
                                    attrs: { cols: "7" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-h6 font-weight-bold",
                                      },
                                      [_vm._v(_vm._s(tutorial.title))]
                                    ),
                                    _vm._l(
                                      tutorial.tags,
                                      function (tag, index) {
                                        return _c(
                                          VChip,
                                          {
                                            key: index,
                                            attrs: {
                                              label: "",
                                              small: "",
                                              outlined: "",
                                            },
                                          },
                                          [
                                            _c(VIcon, [_vm._v("mdi-label")]),
                                            _vm._v(_vm._s(tag)),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v(_vm._s(tutorial.description))]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(VDivider),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
          _vm.openedTutorial !== undefined
            ? _c(
                "FDialog",
                {
                  attrs: {
                    title: _vm.openedTutorial.title,
                    width: "750px",
                    "max-width": "100%",
                    "hide-cancel-and-done": "",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showOpenedTutorial,
                    callback: function ($$v) {
                      _vm.showOpenedTutorial = $$v
                    },
                    expression: "showOpenedTutorial",
                  },
                },
                [
                  _c(
                    VSheet,
                    [
                      _c(
                        VCardTitle,
                        { staticClass: "text-h6 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.openedTutorial.description))]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-4" },
                        _vm._l(_vm.openedTutorial.tags, function (tag, index) {
                          return _c(
                            VChip,
                            { key: index, attrs: { label: "", outlined: "" } },
                            [
                              _c(VIcon, [_vm._v("mdi-label")]),
                              _vm._v(_vm._s(tag)),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(VCardText, [
                        _vm._v(_vm._s(_vm.openedTutorial.body)),
                      ]),
                      _c(
                        VTabs,
                        {
                          attrs: {
                            color: "themedButtonTextColor",
                            centered: "",
                            "icons-and-text":
                              !_vm.$store.state.userPreferences.compactMode,
                          },
                        },
                        [
                          _c(VTabsSlider, { attrs: { color: "accent" } }),
                          _vm._l(
                            _vm.openedTutorial.assets,
                            function (assets, assetType, index) {
                              return _c(
                                VTab,
                                {
                                  key: assetType,
                                  attrs: { href: "#" + index + assetType },
                                },
                                [
                                  _vm._v(_vm._s(assetType)),
                                  _c(VIcon, [_vm._v("mdi-devices")]),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(
                            _vm.openedTutorial.assets,
                            function (assets, assetType, index) {
                              return _c(
                                VTabItem,
                                {
                                  key: assetType,
                                  staticClass: "pt-4",
                                  attrs: {
                                    "icons-and-text":
                                      !_vm.$store.state.userPreferences
                                        .compactMode,
                                    value: index + assetType,
                                  },
                                },
                                [
                                  assets.screencasts.length >= 1
                                    ? [
                                        _c(
                                          VCardTitle,
                                          {
                                            staticClass:
                                              "text-h6 font-weight-bold",
                                          },
                                          [_vm._v("Screencasts")]
                                        ),
                                        _c(VDivider, { staticClass: "hr" }),
                                        _c(
                                          VCardText,
                                          [
                                            _c(
                                              VCarousel,
                                              {
                                                attrs: {
                                                  "show-arrows":
                                                    assets.screencasts.length >
                                                    1,
                                                },
                                              },
                                              _vm._l(
                                                assets.screencasts,
                                                function (screencast) {
                                                  return _c(
                                                    VCarouselItem,
                                                    {
                                                      key: screencast.path,
                                                      attrs: {
                                                        transition:
                                                          "fade-transition",
                                                        "reverse-transition":
                                                          "fade-transition",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "video",
                                                        {
                                                          attrs: {
                                                            autoplay: "",
                                                            loop: "",
                                                            width: "100%",
                                                          },
                                                        },
                                                        [
                                                          _c("source", {
                                                            attrs: {
                                                              src: _vm.getSrcPath(
                                                                screencast.path
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "FAlert",
                                          {
                                            attrs: {
                                              text: "",
                                              prominent: "",
                                              dissmisable: "",
                                              type: "warning",
                                              border: "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Sadly, there are no screencasts for this tutorial :("
                                            ),
                                          ]
                                        ),
                                      ],
                                  assets.snapshots.length > 1
                                    ? [
                                        _c(
                                          VCardTitle,
                                          {
                                            staticClass:
                                              "text-h6 font-weight-bold",
                                          },
                                          [_vm._v("Screenshots")]
                                        ),
                                        _c(VDivider, { staticClass: "hr" }),
                                        _c(
                                          VCardText,
                                          [
                                            _c(
                                              VCarousel,
                                              {
                                                attrs: {
                                                  "show-arrows":
                                                    assets.snapshots.length > 1,
                                                },
                                              },
                                              _vm._l(
                                                assets.snapshots,
                                                function (snapshot) {
                                                  return _c(
                                                    VCarouselItem,
                                                    {
                                                      key: snapshot.path,
                                                      attrs: {
                                                        transition:
                                                          "fade-transition",
                                                        "reverse-transition":
                                                          "fade-transition",
                                                      },
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: _vm.getSrcPath(
                                                            snapshot.path
                                                          ),
                                                          contain: "",
                                                          "max-width": "100%",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : assets.screencasts.length >= 1 &&
                                      assets.snapshots.length < 1
                                    ? [
                                        _c(
                                          "FAlert",
                                          {
                                            attrs: {
                                              text: "",
                                              prominent: "",
                                              dissmisable: "",
                                              type: "warning",
                                              border: "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Sadly, there are no screenshots for this tutorial :("
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "FAlert",
                        {
                          attrs: {
                            type: "info",
                            prominent: "",
                            text: "",
                            border: "left",
                          },
                        },
                        [
                          _c(
                            VRow,
                            {
                              attrs: {
                                align: "center",
                                "no-gutters": "",
                                dense:
                                  _vm.$store.state.userPreferences.compactMode,
                              },
                            },
                            [
                              _c(VCol, { staticClass: "grow" }, [
                                _c("div", { staticClass: "text-h6" }, [
                                  _vm._v("Have an idea for a tutorial?"),
                                ]),
                              ]),
                              _c(
                                VCol,
                                { staticClass: "shrink" },
                                [
                                  _c("FButton", {
                                    attrs: {
                                      color: "info",
                                      outlined: "",
                                      prependIcon: "mdi-comment-quote",
                                      buttonText: "Tell us",
                                      tooltip:
                                        "Send some feedback to FlyPaper or ask a question via email.",
                                    },
                                    on: { click: _vm.suggestATutorial },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "FDialog",
            {
              attrs: {
                title: "Suggest a tutorial",
                width: "750px",
                "max-width": "100%",
                fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                doneText: "Submit",
                cancelText: "Cancel",
              },
              on: {
                cancel: function ($event) {
                  _vm.showSuggestTutorialDialog = false
                },
                done: function () {
                  _vm.showSuggestTutorialDialog = false
                  _vm.submitSuggestedTutorial()
                },
              },
              model: {
                value: _vm.showSuggestTutorialDialog,
                callback: function ($$v) {
                  _vm.showSuggestTutorialDialog = $$v
                },
                expression: "showSuggestTutorialDialog",
              },
            },
            [
              _c(VTextField, {
                staticClass: "mt-1",
                attrs: {
                  "prepend-inner-icon": "mdi-format-title",
                  label: "Subject",
                  placeholder: "Describe in a sentance your tutorial idea",
                  filled: "",
                  rules:
                    _vm.emailSubject.length === 0
                      ? [_vm.rules.notBlank]
                      : [_vm.rules.noRule],
                  "hide-details": "",
                  dense: _vm.$store.state.userPreferences.compactMode,
                },
                model: {
                  value: _vm.emailSubject,
                  callback: function ($$v) {
                    _vm.emailSubject = $$v
                  },
                  expression: "emailSubject",
                },
              }),
              _c(VTextarea, {
                staticClass: "mt-2",
                attrs: {
                  label: "Description",
                  "prepend-inner-icon": "mdi-text-short",
                  placeholder:
                    "Give us more details about your idea for a tutorial",
                  rules:
                    _vm.emailBody.length === 0
                      ? [_vm.rules.notBlank]
                      : [_vm.rules.noRule],
                  counter: "",
                  filled: "",
                },
                model: {
                  value: _vm.emailBody,
                  callback: function ($$v) {
                    _vm.emailBody = $$v
                  },
                  expression: "emailBody",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }