var render, staticRenderFns
import script from "./buttonDisplay.client?vue&type=script&lang=ts&"
export * from "./buttonDisplay.client?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/flypaper/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('441a4a3e')) {
      api.createRecord('441a4a3e', component.options)
    } else {
      api.reload('441a4a3e', component.options)
    }
    
  }
}
component.options.__file = "components/widgets/fToolbar/buttonDisplay/buttonDisplay.component.vue"
export default component.exports