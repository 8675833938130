// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-4[0].rules[0].use[1]!../../../../node_modules/viewerjs/dist/viewer.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-holder[data-v-464fc173] {
  border-radius: 10px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/attachments/viewer/image/image.style.less","webpack://./image.style.less"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,WAAA;ACAJ","sourcesContent":["@import \"/node_modules/viewerjs/dist/viewer.css\";\n\n.image-holder {\n    border-radius: 10px;\n    width: 100%;\n}\n","@import \"/node_modules/viewerjs/dist/viewer.css\";\n.image-holder {\n  border-radius: 10px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
