import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VBadge,
    {
      attrs: {
        content: _vm.content,
        value: _vm.value,
        right: _vm.right,
        overlap: _vm.overlap,
        color: _vm.color,
        "offset-x": _vm.offsetX,
        "offset-y": _vm.offsetY,
        bordered: _vm.bordered,
      },
      scopedSlots: _vm._u([
        {
          key: "badge",
          fn: function (ref) {
            return [
              _c("span", { style: _vm.styles }, [_vm._v(_vm._s(_vm.content))]),
            ]
          },
        },
      ]),
    },
    [
      _vm.icon
        ? _c("span", [_c(VIcon, [_vm._v(_vm._s(_vm.icon))])], 1)
        : _vm._e(),
      _vm.text
        ? _c("span", [
            _c("div", { staticClass: "pa-2", style: _vm.customStyle }, [
              _vm._v(_vm._s(_vm.text)),
            ]),
          ])
        : _vm._e(),
      _vm.subTitle
        ? _c("span", [
            _c("div", { staticClass: "subtitle grey--text text-center" }, [
              _vm._v(_vm._s(_vm.subTitle)),
            ]),
          ])
        : _vm._e(),
      _vm.checkbox
        ? _c(
            "span",
            [
              _c(
                VSimpleCheckbox,
                _vm._g(
                  _vm._b(
                    { on: { change: _vm.change } },
                    "v-simple-checkbox",
                    _vm.Props,
                    false
                  ),
                  _vm.On
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }