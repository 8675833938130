// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info[data-v-07b86499] {
  background-color: #2196f3;
}
.error[data-v-07b86499] {
  background-color: #ff5252;
}
.warning[data-v-07b86499] {
  background-color: #fb8c00;
}
.success[data-v-07b86499] {
  background-color: #4caf50;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/fBanner/fBanner.style.mobile.less","webpack://./fBanner.style.mobile.less"],"names":[],"mappings":"AAKA;EACI,yBAAA;ACJJ;ADOA;EACI,yBAAA;ACLJ;ADQA;EACI,yBAAA;ACNJ;ADSA;EACI,yBAAA;ACPJ","sourcesContent":["@info-color: rgb(33, 150, 243);\n@error-color: rgb(255, 82, 82);\n@warning-color: rgb(251, 140, 0);\n@success-color: rgb(76, 175, 80);\n\n.info {\n    background-color: @info-color;\n}\n\n.error {\n    background-color: @error-color;\n}\n\n.warning {\n    background-color: @warning-color;\n}\n\n.success {\n    background-color: @success-color;\n}\n",".info {\n  background-color: #2196f3;\n}\n.error {\n  background-color: #ff5252;\n}\n.warning {\n  background-color: #fb8c00;\n}\n.success {\n  background-color: #4caf50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
