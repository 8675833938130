import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "fBanner" } },
    [
      _c(
        VSheet,
        { staticClass: "mt-2 float-on-bottom" },
        [
          _c(
            VCol,
            _vm._l(_vm.fBannerStore.banners, function (item, index) {
              return _c(
                VRow,
                { key: index },
                [
                  item.showBanner
                    ? _c(
                        VBanner,
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            dark: "",
                            app: "",
                            color: item.bannerColor,
                            "single-line": "",
                            dismissable: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(VIcon, { attrs: { large: "" } }, [
                                      _vm._v(_vm._s(item.icon)),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "actions",
                                fn: function () {
                                  return [
                                    item.showButton
                                      ? _c("FButton", {
                                          attrs: {
                                            outlined: "",
                                            "prepend-icon": item.buttonIcon,
                                            "button-text": item.buttonText,
                                            loading: item.buttonLoading,
                                          },
                                          on: { click: item.buttonCallback },
                                        })
                                      : _vm._e(),
                                    !item.hideCancel
                                      ? _c("FButton", {
                                          attrs: {
                                            outlined: "",
                                            prependIcon: "mdi-close",
                                          },
                                          on: {
                                            click: function ($event) {
                                              item.showBanner = false
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_vm._v(_vm._s(item.text))]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }