// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfViewer[data-v-6355b39b] {
  margin: auto;
  display: block;
  border: 3px solid black;
}
.pdfViewer-parent[data-v-6355b39b] {
  transform-origin: center center;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: white;
}
.loading[data-v-6355b39b] {
  width: 100%;
  height: 50%;
  background-color: white;
  position: absolute;
}
.zoomLevel[data-v-6355b39b] {
  height: 46px;
  max-width: 100px;
}
.pageNumber[data-v-6355b39b] {
  height: 46px;
  max-width: 50px;
}
`, "",{"version":3,"sources":["webpack://./components/attachments/viewer/document/document.style.less","webpack://./document.style.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,uBAAA;ACCJ;ADEA;EACI,+BAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,uBAAA;ACAJ;ADGA;EACI,WAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;ACDJ;ADGA;EACI,YAAA;EACA,gBAAA;ACDJ;ADGA;EACI,YAAA;EACA,eAAA;ACDJ","sourcesContent":[".pdfViewer {\n    margin: auto;\n    display: block;\n    border: 3px solid black;\n}\n\n.pdfViewer-parent {\n    transform-origin: center center;\n    width: 100%;\n    height: 100%;\n    z-index: 5;\n    background-color: white;\n}\n\n.loading {\n    width: 100%;\n    height: 50%;\n    background-color: white;\n    position: absolute;\n}\n.zoomLevel {\n    height: 46px;\n    max-width: 100px;\n}\n.pageNumber {\n    height: 46px;\n    max-width: 50px;\n}\n",".pdfViewer {\n  margin: auto;\n  display: block;\n  border: 3px solid black;\n}\n.pdfViewer-parent {\n  transform-origin: center center;\n  width: 100%;\n  height: 100%;\n  z-index: 5;\n  background-color: white;\n}\n.loading {\n  width: 100%;\n  height: 50%;\n  background-color: white;\n  position: absolute;\n}\n.zoomLevel {\n  height: 46px;\n  max-width: 100px;\n}\n.pageNumber {\n  height: 46px;\n  max-width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
