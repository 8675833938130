import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { style: _vm.customStyle },
    [
      _vm.groupBy !== undefined
        ? _c(
            VCol,
            { attrs: { cols: "5" } },
            [
              _c("FDropdown", {
                attrs: {
                  label: "Group by",
                  dense: "",
                  outlined: "",
                  value: _vm.groupBy,
                  items: _vm.groupKeys,
                  itemText: "key",
                  itemValue: "value",
                },
                on: {
                  input: function (val) {
                    return _vm.updateGroupBy(val)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        VCol,
        { attrs: { cols: "5" } },
        [
          _c("FDropdown", {
            attrs: {
              outlined: "",
              label: "Sort by",
              dense: "",
              clearable:
                _vm.sortBy === _vm.sortKeys[0].value || _vm.hideSortClerable
                  ? false
                  : true,
              value: _vm.sortBy,
              items: _vm.sortKeys,
              itemText: "key",
              itemValue: "value",
            },
            on: {
              input: function (val) {
                return _vm.updateSortBy(val)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm.sortKeys[0].icon
                  ? {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("span", [_vm._v(_vm._s(item.key))]),
                          _vm._l(item.icon, function (icon, index) {
                            return [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.getIconForRecordType(icon))),
                              ]),
                            ]
                          }),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm.sortDesc !== undefined
        ? _c(
            VCol,
            { attrs: { cols: "2" } },
            [
              _c("FButton", {
                style: _vm.sortDescbuttonStyle,
                attrs: {
                  tooltip: "Sort",
                  icon: "",
                  prependIcon: _vm.sortDesc
                    ? "mdi-sort-ascending"
                    : "mdi-sort-descending",
                },
                on: { click: _vm.updateSortOrder },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }