import { render, staticRenderFns } from "./fBanner.template?vue&type=template&id=07b86499&scoped=true&lang=pug&"
import script from "./fBanner.client?vue&type=script&lang=ts&"
export * from "./fBanner.client?vue&type=script&lang=ts&"
import style0 from "./fBanner.style?vue&type=style&index=0&id=07b86499&scoped=true&lang=scss&"
import style1 from "./fBanner.style.mobile?vue&type=style&index=1&id=07b86499&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b86499",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/flypaper/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07b86499')) {
      api.createRecord('07b86499', component.options)
    } else {
      api.reload('07b86499', component.options)
    }
    module.hot.accept("./fBanner.template?vue&type=template&id=07b86499&scoped=true&lang=pug&", function () {
      api.rerender('07b86499', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/widgets/fBanner/fBanner.component.vue"
export default component.exports